.not-found {
    padding-top: 100px;
    height: 100vh;
}
.not-found__title {z-index: 1;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    margin-top: 0;
    font-size: 80px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.not-found__text {
    text-align: center;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
    margin-top: 30px;
}
.button_type_to-main {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 170%;
    display: flex;
    position: relative;
    text-decoration: none;
    margin-top: 20px;
    color: rgba(255, 255, 255, .6);
    text-align: center;
    justify-content: center;
}
.button_type_to-main:hover {
    opacity: .7;
}

@media screen and (max-width: 991px) {
    .not-found__title {
        font-size: 64px;
    }
    .not-found__text {
        font-size: 36px;
    }
}

@media screen and (max-width: 767px) {
    .not-found__title {
        font-size: 40px;
    }
    .not-found__text {
        font-size: 28px;
        line-height: 150%;
    }
    .button_type_to-main {
        font-size: 16px;
    }
}