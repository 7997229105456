.features {
    background-image: url(../../images/background.png), linear-gradient(#230f43, #241046);
    background-position: 0 0, 0 0;
    background-size: contain, auto;
    padding-top: 145px;
    padding-bottom: 83px;
    position: relative;
    overflow: hidden;
    mix-blend-mode: normal;
}
.features__container {
    z-index: 1;
    max-width: 1920px;
    text-align: center;
    margin: 0;
    padding-left: 62px;
    padding-right: 62px;
    position: relative;
}
.features__label {
    text-align: center;
    background-image: linear-gradient(91.07deg, #ff5621, #ffa621);
    display: inline-block;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.features__text {
    margin-bottom: 72px;
    padding-top: 20px;
    padding-bottom: 0;
    display: flex;
    position: relative;
    justify-content: center;
}
.features__title {
    text-align: center;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.features__cards {
    width: 100%;
    max-width: 1440px;
    justify-content: space-around;
    margin-top: 12px;
    padding-bottom: 0;
    transition: opacity .2s;
    display: flex;
    position: relative;
}
.features__card {
    background-color: #2e1553;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 32px;
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    padding: 24px 48px 44px;
    text-align: center;
}
.features__img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.features__head {
    padding-top: 32px;
    padding-bottom: 16px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
}
.features__subhead {
    z-index: 1;
    color: rgba(255, 255, 255, .6);
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    display: flex;
    position: relative;
}

@media screen and (max-width: 991px) {
    .features {
        padding-top: 140px;
    }
    .features__container {
        padding-left: 12px;
        padding-right: 12px;
    }
    .features__label {
        font-size: 18px;
        line-height: 24px;
    }
    .features__text {
        padding-top: 18px;
    }
    .features__title {
        width: 500px;
        padding-right: 0;
        font-size: 36px;
        margin: 0 auto;
    }
    .features__card {
        margin-left: 8px;
        margin-right: 8px;
        padding-bottom: 32px;
        padding-left: 12px;
        padding-right: 12px;
    }
    .features__img {
        width: 140px;
    }
    .features__subhead {
        font-size: 14px;
    }
    .features__cards {
        margin-top: 0;
    }
}

@media screen and (max-width: 767px) {
    .features {
        padding-top: 120px;
    }
    .features__label {
        font-size: 16px;
        line-height: 28px;
    }
    .features__container {
        padding-left: 16px;
        padding-right: 16px;
    }
    .features__text {
        padding-top: 12px;
        padding-bottom: 48px;
        margin-bottom: 0;
    }
    .features__title {
        font-size: 28px;
        line-height: 150%;
    }
    .features__cards {
        display: block;
    }
    .features__card {
        margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 20px;
    padding-left: 38px;
    padding-right: 38px;
    }
    .features__head {
        padding-top: 28px;
        padding-bottom: 12px;
        font-size: 20px;
    }
}

@media screen and (max-width: 479px) {
    .features__label {
        line-height: 28px;
    }
    .features__title {
        line-height: 150%;
    }
}