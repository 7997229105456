.footer {
    width: 100%;
    background-image: linear-gradient(#361865, #361764 20%, #8b335b 37%, #f78c4c 65%, #fc904a);
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer__copyright {
    z-index: 2;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: translate(72px, -56px);
    z-index: 1;
    color: rgba(255, 255, 255, .6);
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    display: flex;
}
.footer__clouds {
    width: 100%;
    max-width: none;
    margin-bottom: -266px;
    padding-bottom: 0;
    position: static;
}
.footer__container {
    position: relative;
    line-height: 0;
}
.footer__row {
    z-index: 2;
    text-align: center;
    position: absolute;
    top: 16%;
    bottom: auto;
    left: 0%;
    right: 0%;
    transform: translate(0);
}
.footer__title {
    text-align: center;
    padding-right: 0;
    margin: 0;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.footer__button {
    width: auto;
    color: #141414;
    background-color: #fff;
    border-radius: 100px;
    margin-top: 48px;
    padding: 14px 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    transition-duration: .3s, .3s;
    display: inline-block;
    border: 1px solid #fff;
    cursor:pointer;
}
.footer__button:hover {
    background-color: transparent;
    color: #fff;
}
.footer__plane {
    width: 24%;
    position: absolute;
    top: auto;
    bottom: 11%;
    left: 0%;
    right: auto;
    transform: translate(143%);
    z-index: 2;
}
.footer__img {
    width: 100%;
    max-width: none;
    position: relative;
}
.footer__img2 {
    display: none;
}

@media screen and (max-width: 991px) {
    .footer__clouds {
        margin-bottom: -104px;
    }
    .footer__title {
        padding-right: 0;
        font-size: 36px;
        width: 500px;
        margin: 0 auto;
    }
    .footer__button {
        margin-top: 32px;
        padding: 12px 24px;
        font-size: 16px;
    }
    .footer__copyright {
        font-size: 14px;
        text-align: center;
        top: auto;
        bottom: 0%;
        left: 0%;
        right: 0%;
        transform: translate(0, -16px);
    }
    .footer__plane {
        transform: translate(156%);
    }
}

@media screen and (max-width: 767px) {
    .footer__clouds {
        margin-bottom: 23px;
    }
    .footer__row {
        z-index: 1;
        top: 0%;
        transform: translate(0);
    }
    .footer__title {
        width: 400px;
        font-size: 28px;
        line-height: 150%;
    }
    .footer__button {
        margin-top: 26px;
        font-weight: 500;
    }
    .footer__img {
        display: none;
    }
    .footer__img2 {
        width: 850px;
        display: block;
    }
    .footer__plane {
        width: 243px;
    bottom: 12%;
    transform: translate(105%);
    }
}

@media screen and (max-width: 479px) {
    .footer__clouds {
        /* width: 835px;
        max-width: none; */
        margin-bottom: -6px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
    }
    .footer__title {
        width: 320px;
        line-height: 150%;
        display: block;
    }
    .footer__row {
        top: -15%;
    }
    .footer__button {
        padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
    }
    .footer__plane {
        transform: translate(120%);
    }
}
