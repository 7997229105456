.news {
    padding-left: 62px;
    padding-right: 62px;
    background-image: url(../../images/background.png), linear-gradient(#341c69, #361865);
    background-position: 0 0, 0 0;
    background-size: contain, auto;
    padding-top: 110px;
    padding-bottom: 150px;
    display: block;
    overflow: hidden;
    position: relative;
}
.news__row {
    z-index: 1;
    max-width: 1920px;
    text-align: center;
    margin: 0;
    padding-left: 62px;
    padding-right: 62px;
    position: relative;
}
.news__label {
    text-align: center;
    background-image: linear-gradient(91.07deg, #ff5621, #ffa621);
    display: inline-block;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.news__title {
    margin-bottom: 72px;
    padding-top: 20px;
    padding-bottom: 0;
    justify-content: center;
    display: flex;
    position: relative;
    text-align: center;
    margin-top: 0;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
/* .news__container {
    z-index: 2;
    max-width: 1440px;
    margin-left: 0;
    margin-right: 0;
    position: relative;
}
.news__nav {
    z-index: 10;
    text-align: right;
    margin-right: 0;
    position: absolute;
    top: -100px;
    bottom: auto;
    left: auto;
    right: 0%;
    display: flex;
}
.news__nav-link {
    cursor: pointer;
    margin-right: 10px;
}
.news__arrow {
    width: 28px;
    height: 28px;
    color: rgba(255, 255, 255, .6);
    transition: all .3s;
}
.news__arrow:hover {
    color: #fff;
} */
.news__cards {}
.news__card {
    width: 23.59%;
    min-width: 23.59%;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
}
.news__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 16px;
}
.news__data {
    max-width: 295px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 170%;
    margin-top: 0;
}
.news__link:nth-child(2) {
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
    margin-top: 18px;
    margin-bottom: 8px;
    text-decoration: none;
    transition: color .3s;
}
.news__link:last-child {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    transition: color .3s;
}
.news__head {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}
.news__moon {
    z-index: 0;
    width: 238px;
    position: absolute;
    top: 27px;
    bottom: auto;
    left: -29px;
    right: auto;
}

@media screen and (max-width: 991px) {
    .news {
        padding-bottom: 50px;
        padding-right: 12px;
        padding-left: 12px;
    }
    .news__row {
        padding: 0;
    }
    .news__label {
        font-size: 18px;
        line-height: 24px;
    }
    .news__title {
        padding-top: 18px;
        padding-bottom: 48px;
        padding-right: 0;
        font-size: 36px;
        margin: 0;
    }
    .news__card {
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media screen and (max-width: 767px) {
    .news {
        padding-bottom: 100px;
        padding-left: 16px;
        padding-right: 0;
    }
    .news__title {
        padding-top: 12px;
        padding-bottom: 48px;
        font-size: 28px;
        line-height: 150%;
    }
    .news__moon {
        display: none;
    }
    .news__card {
        width: 46.8%;
        min-width: 46.8%;
        padding-left: 0;
        padding-right: 16px;
    }
}

@media screen and (max-width: 479px) {
    .news {
        padding-bottom: 110px;
    }
    .news__label {
        line-height: 28px;
    }
    .news__title {
        line-height: 150%;
    }
    .news__card {
        width: 93.2%;
        min-width: 93.2%;
    }
}