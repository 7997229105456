.banner {
    padding-bottom: 188px;
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    object-fit: fill;
    object-position: 50% 50%;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 136px;
    display: flex;
}
.banner__moon {
    z-index: 1;
    width: 57px;
    position: absolute;
    top: 188px;
    bottom: auto;
    left: 139px;
    right: auto;
}
.banner__minairplane {
    z-index: 1;
    height: 85px;
    position: absolute;
    top: auto;
    bottom: 32px;
    left: auto;
    right: 276px;
    width: 149px;
    object-fit: fill;
    position: absolute;
}
.banner__airplane {
    z-index: 2;
    top: 302px;
    bottom: auto;
    left: auto;
    right: 67%;
    width: 383px;
    object-fit: fill;
    position: absolute;
}
.banner__comet {
    z-index: 2;
    top: 127px;
    bottom: auto;
    left: 78%;
    right: auto;
    width: 434px;
    position: absolute;
}
.banner__block {
    padding-bottom: 42px;
    z-index: 1;
    flex: 0 auto;
    position: relative;
}
.banner__title {
    z-index: 1;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    margin-top: 0;
    font-size: 80px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.banner__button {
    width: auto;
    color: #141414;
    text-align: center;
    object-fit: fill;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 100px;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-decoration: none;
    transition: background-color .3s, color .3s;
    display: flex;
}
.banner__icon {
    height: 20px;
}
.banner__icon::before {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}
.banner__paragraph {
    margin-bottom: 0;
    margin-left: 12px;
    padding-left: 0;
    padding-right: 0;
    line-height: 28px;
    font-size: 18px;
    margin-top: 0;
}
.banner__button:hover .banner__icon{
    color: #fff;
}
.banner__button:hover .banner__paragraph{
    color: #fff;
}
.banner__button:hover {
    background-color: transparent;
}

@media screen and (max-width: 991px) {
    .banner {
        padding-top: 100px;
        padding-bottom: 250px;
    }
    .banner__title {
        font-size: 64px;
    }
    .banner__button {
        padding: 12px 24px;
    }
    .banner__airplane{
        width: 250px;
        top: 375px;
        left: 94px;
   }
   .banner__comet {
        width: 230px;
        top: 312px;
        right: 8px;
   }
   .banner__moon {
        display: none;
   }
   .banner__minairplane {
        display: none;
   }
}

@media screen and (max-width: 767px) {
.banner {
    padding-top: 80px;
    padding-bottom: 206px;
}
.banner__title {
    font-size: 40px;
}
.banner__block {
    padding-bottom: 32px;
}
.banner__airplane {
    width: 165px;
    top: 307px;
    left: 22px;
}
.banner__comet {
    width: 126px;
    top: 264px;
    right: -40px;
}
}

@media screen and (max-width: 479px) {
    .banner {
        padding-left: 8px;
        padding-right: 8px;
    }
    .banner__button {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .banner__airplane {
        top: auto;
        bottom: 69px;
        left: 22px;
        right: auto;
    }
    .banner__comet {
        top: auto;
        bottom: 129px;
        left: auto;
        right: -41px;
    }
}