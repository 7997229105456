.header {
    z-index: 1;
    width: auto;
    max-width: 1920px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 72px;
    padding-right: 72px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    opacity: 1;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
    display: flex;
}
.header__logo {
    display: block;
    max-width: 100%;
}
.header__logo-img {
    width: 166px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.header__items {
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    text-align: center;
    object-fit: fill;
    flex: 0 auto;
    display: flex;
}
.header__item {
    padding-left: 26px;
    padding-right: 26px;
    color: #fff;
    text-decoration: none;
}
.header__item:hover {
    opacity: .7;
}
.header__menu {
    align-items: center;
    margin-bottom: 0;
    font-weight: 400;
    transition: background-color .3s, color .3s;
    display: flex;
    position: static;
    transition: color .3s;
    font-size: 18px;
    line-height: 160%;
    margin: 0;
}
.header__language {
    padding-left: 4px;
    padding-right: 0;
    text-decoration: none;
    display: flex;
}
.header__language:hover {
    color: #141414;
}
.header__button {
    transition: color .3s, background-color .3s;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #fff;
    border-radius: 100px;
    margin-left: 28px;
    padding: 12px 20px;
    font-size: 18px;
    line-height: 28px;
    display: block;
    color: #fff;
    cursor:pointer;
}
.header__language:hover .header__button{
    color: #141414;
    background-color: #fff;
}

@media screen and (max-width: 1439px) {
    .header__item {
        padding: 0 16px;
    }
}
@media screen  and (max-width: 991px){
    .header__items {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .header {
        padding-left: 40px;
        padding-right: 40px;
    }
    .header__logo-img {
        width: 110px;
        height: 28px;
    }
    .header__button {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
    }
}

@media screen and (max-width: 479px) {
.header {
    padding-left: 32px;
    padding-right: 32px;
}
}