.rewards {
    background-image: url(../../images/background.png), linear-gradient(#341b68, #341c69);
    background-position: 0 0, 0 0;
    background-size: contain, auto;
    margin-bottom: 0;
    padding-top: 200px;
    padding-bottom: 151px;
    position: relative;
    overflow: hidden;
}
.rewards__row {
    align-items: end;
    margin-left: 0;
    margin-right: 0;
    padding-left: 182px;
    padding-right: 182px;
    display: flex;
    left: 50%;
    z-index: 1;
}
.rewards__column {}
.rewards__column {}
.rewards__label {
    padding-bottom: 20px;
    background-image: linear-gradient(91deg, #ff5621, #ffa621);
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.rewards__title {
    padding-bottom: 12px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 60px;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.rewards__text {
    color: rgba(255, 255, 255, .7);
    margin-bottom: 0;
    font-size: 18px;
    line-height: 160%;
}
.rewards__list {
    margin-top: 0;
    padding-top: 12px;
    display: block;
}
.rewards__item {
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
}
.rewards__img {
    margin-right: 16px;
}
.rewards__paragraph {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 160%;
    color: #fff;
    margin: 0;
}
.rewards__plane {
    width: 550px;
}
.rewards__planet {
    z-index: 1;
    width: 228px;
    position: absolute;
    top: 84px;
    bottom: auto;
    left: 36%;
    right: auto;
}

@media screen and (max-width: 991px) {
    .rewards {
        padding-top: 58px;
    }
    .rewards__row {
        padding-left: 24px;
        padding-right: 24px;
    }
    .rewards__label {
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 20px;
    }
    .rewards__title {
        font-size: 36px;
        padding-right: 0;
    }
    .rewards__text {
        font-size: 14px;
        line-height: 170%;
        margin-top: 0;
    }
    .rewards__paragraph {
        font-size: 14px;
        line-height: 170%;
    }
    .rewards__column:last-child {
        width: 56.3333%;
        padding: 0 10px;
        z-index: 1;
    }
    .rewards__planet {
        top: 348px;
        z-index: 0;
        width: 228px;
        position: absolute;
        left: 558px;
    }
}

@media screen and (max-width: 767px) {
    .rewards {
        padding-top: 2px;
    padding-bottom: 46px;
    }
    .rewards__row {
        padding-left: 16px;
        padding-right: 16px;
        display: block;
    }
    .rewards__label {
        padding-bottom: 12px;
        font-size: 16px;
    }
    .rewards__title {
        padding-bottom: 26px;
        padding-right: 54px;
        font-size: 28px;
        line-height: 150%;
    }
    .rewards__text {
        padding-right: 54px;
    }
    .rewards__list {
        padding-top: 16px;
    }
    .rewards__item {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .rewards__paragraph {
        font-size: 14px;
        line-height: 170%;
    }
    .rewards__column:last-child {
        width: 100%;
        left: auto;
        right: auto;
        text-align: center;
        padding-top: 40px;
    }
    .rewards__plane {
        width: 460px;
    text-align: center;
    }
    .rewards__planet {
        top: 260px;
    bottom: auto;
    left: auto;
    right: -12%;
    }
}

@media screen and (max-width: 479px) {
    .rewards__plane {
        width: 340px;
        max-width: 100%;
    }
    .rewards__column:last-child {
        padding-top: 60px;
    }
    .rewards__paragraph {
        font-size: 14px;
    line-height: 170%;
    }
    .rewards__planet {
        top: 41px;
    bottom: auto;
    left: auto;
    right: -90px;
    }
}