.planes {
    background-image: url(../../images/background.png), linear-gradient(#2b1657, #341b68);
    background-position: 0 0, 0 0;
    background-size: contain, auto;
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 18px;
    position: relative;
    overflow: hidden;
}
.planes__moon {
    width: 80px;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 121px;
    z-index: 0;
}
.planes__row {
    padding-bottom: 60px;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    padding-left: 182px;
    padding-right: 182px;
    display: flex;
    left: 50%;
    z-index: 1;
}
.planes__column:first-child {
    padding-left: 0;
    padding-right: 40px;
}
.planes__column:last-child {
    padding-top: 56px;
    padding-left: 40px;
    display: block;
    max-width: 408px;
}
.planes__label {
    padding-bottom: 20px;
    background-image: linear-gradient(91deg, #ff5621, #ffa621);
    display: inline-block;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}
.planes__title {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 60px;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.planes__text {
    color: rgba(255, 255, 255, .7);
    margin-bottom: 0;
    font-size: 18px;
    line-height: 160%;
    margin: 0;
}
.planes__container {
    align-items: center;
    margin-top: 32px;
    display: flex;
}
.planes__star {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}
.planes__paragraph {
    max-width: 295px;
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 0;
    line-height: 170%;
    color: #fff;
}
.planes__content {
    width: 138%;
    max-width: none;
    text-align: center;
    margin-bottom: 0;
    left: auto;
    z-index: 1;
    position: relative;
}
.planes__cards {
    width: 3500px;
    min-width: auto;
    padding-bottom: 12px;
    margin-top: 12px;
    max-width: 1440px;
    justify-content: space-around;
    display: flex;
    position: relative;
}
.planes__cards:first-child {
    margin-left: -383px;
    left: 17%;
}
.planes__cards:nth-child(2){
    left: 0%;
}
.planes__cards:last-child {
    left: 17%;
    margin-left: -383px;
}
.planes__card {
    -webkit-text-fill-color: inherit;
    background-image: linear-gradient(122.71deg, #4a2a82, #43277a 100%, #fff);
    background-clip: border-box;
    border-radius: 20px;
    flex: 1;
    margin-left: 12px;
    margin-right: 12px;
    padding-top: 32px;
    padding-left: 80px;
    padding-right: 80px;
    height: 100%;
}
.planes__img {
    width: 220px;
    max-width: none;
}
.planes__head {
    padding-top: 18px;
    padding-bottom: 4px;
    text-align: center;
    color: #fff;
    font-weight: 500;
}
.planes__subhead {
    color: rgba(255, 255, 255, .4);
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 24.6px;
    margin: 0;
    padding-bottom: 20px;
}

@media screen and (max-width: 991px) {
    .planes {
        padding-top: 0;
        padding-bottom: 196px;
    }
    .planes__moon {
        margin-top: 904px;
    }
    .planes__row {
        padding-left: 24px;
        padding-right: 24px;
    }
    .planes__label {
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 20px;
    }
    .planes__title {
        font-size: 36px;
        padding-right: 32px;
    }
    .planes__column:first-child {
        padding: 0;
    }
    .planes__text {
        font-size: 14px;
        line-height: 170%;
    }
    .planes__column:last-child {
        padding-left: 0;
    }
    .planes__star {
        width: 40px;
    }
    .planes__container {
        margin-top: 24px;
    }
    .planes__cards {
        margin-top: 6px;
        padding-bottom: 6px;
        justify-content: center;
    }
    .planes__card {
        margin-left: 6px;
    margin-right: 6px;
    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    width: 193px;
    flex: none;
    }
    .planes__img {
        width: 130px;
        height: 56px;
    }
    .planes__head {
        padding-top: 16px;
        padding-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
    }
    .planes__subhead {
        padding-bottom: 12px;
        font-size: 12px;
    }
    .planes__cards:first-child {
        left: 8%;
    }
    .planes__cards:nth-child(2) {
        left: -16%;
    }
    .planes__cards:last-child {
        left: 8%;
    }
}

@media screen and (max-width: 767px) {
    .planes {
        padding-bottom: 150px;
    }
    .planes__moon {
        margin-top: 719px;
    }
    .planes__title {
        font-size: 28px;
    }
    .planes__row {
        padding-left: 16px;
        padding-right: 16px;
        display: block;
        padding-bottom: 30px;
    }
    .planes__label {
        padding-bottom: 12px;
        font-size: 16px;
    }
    .planes__column:last-child {
        padding-top: 24px;
        max-width: 100%;
    }
    .planes__text {
        padding-right: 54px;
    }
    .planes__container {
        margin-top: 20px;
    }
    .planes__subhead {
        padding-bottom: 10px;
        font-size: 10px;
        line-height: 10.7px;
    }
    .planes__card {
        padding-top: 12px;
    padding-right: 32px;
    width: 154px;
    }
    .planes__cards:nth-child(2) {
        left: 0%;
        justify-content: flex-start;
    }
    .planes__head {
        font-size: 12px;
        line-height: 12.6px;
        padding-top: 14px;
        padding-bottom: 6px;
    }
}

@media screen and (max-width: 479px) {
.planes__text {
    padding-right: 32px;
}
.planes__card {
    padding-left: 24px;
    padding-right: 24px;
    width: 96px;
}
.planes__img {
    width: 96px;
    height: 41px;
}
.planes__cards:nth-child(2) {
    left: -3%;
}
.planes__cards:first-child {
    left: -4%;
}
.planes__cards:last-child {
    left: -4%;
}
.planes__moon {
    width: 36px;
    margin-top: 871px;
    top: auto;
    bottom: 80px;
    left: auto;
    right: 100px;
}
}