.popup.active {
    z-index: 3;
    width: 100vw;
    height: 100vh;
    -webkit-text-fill-color: inherit;
    object-fit: fill;
    background-color: rgba(0, 0, 0, .4);
    background-clip: border-box;
    padding-bottom: 0;
    padding-right: 0;
    position: fixed;
    top: 0px;
    display: flex;
}
.popup {
    display: none;
}
.popup__container {
    background-color: #35195f;
    border-radius: 24px;
    flex-wrap: nowrap;
    display: flex;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    overflow: hidden;
    transform: translate(-50%, -50%);
}
.popup__close {
    position: absolute;
    top: 24px;
    bottom: auto;
    left: auto;
    right: 24px;
    cursor: pointer;
}
.popup__column {
    height: 480px;
}
.popup__column:last-child {
    padding: 40px;
    height: 100%;
}
.popup__img {
    height: 480px;
    height: 100%;
    max-height: none;
    max-width: none;
    display: inline-block;
}
.popup__img_mobile {
    display: none;
}
.popup__form {}
.popup__title {
    margin-bottom: 40px;
    margin-top: 0;
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    line-height: 130%;
}
.popup__label {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.popup__input {
    width: 348px;
    height: 16px;
    color: #fff;
    background-color: #442572;
    border: 1px #000;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 16px;
    font-size: 14px;
    line-height: 1.42857;
    display: block;
    margin-top: 16px;
}
.popup__input:focus {
    outline:none;
}
.popup__input::placeholder {
    color: #fff;
    opacity: .5;
  }
.popup__labels {
    display: flex;
}
.popup__head {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.popup__head:last-child {
    color: rgba(255, 255, 255, .5);
    margin-bottom: 16px;
    padding-left: 4px;
    font-weight: 400;
}
.popup__text {
    color: #fff;
    background-color: #442572;
    border: 0 solid #000;
    border-radius: 12px;
    margin-bottom: 32px;
    padding: 16px;
    height: auto;
    width: 91.6%;
    font-size: 14px;
}
.popup__text::placeholder {
    color: #fff;
    opacity: .5;
}
.popup__text:focus {
    outline:none;
}
.popup__button {
    width: 380px;
    color: #141414;
    background-color: #fff;
    border-radius: 100px;
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: inherit;
    cursor: pointer;
    border: none;
}

@media screen and (max-width: 991px) {
    .popup__img {
        width: 350px;
    }
    .popup__input {
        width: 218px;
    }
    .popup__text {
        width: 218px;
    }
    .popup__button {
        width: 250px;
    }
}

@media screen and (max-width: 767px) {
    .popup__container {
        border-radius: 16px;
        display: block;
    }
    .popup__img {
        display: none;
    }
    .popup__img_mobile {
        display: block;
        width: 100%;
    }
    .popup__column {
        height: 100%;
    }
    .popup__column:last-child {
        padding: 32px 16px 24px;
    }
    .popup__title {
        font-size: 28px;
        margin-bottom: 32px;
    }
    .popup__label {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
    }
    .popup__input {
        width: 317px;
        margin-bottom: 24px;
    }
    .popup__text {
        width: 318px;
    }
    .popup__button {
        width: 358px;
    }
}

@media screen and (max-width: 479px) {
    .popup__container {
        width: 94%;
        margin-left: 0;
        margin-right: 0;
        left: 50%;
    }
    .popup__input {
        width: 92.5%;
    }
    .popup__text {
        width: 92.5%;
    }
    .popup__button {
        width: 100%;
    }
}