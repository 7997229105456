.benefits {
    -webkit-text-fill-color: inherit;
    background-image: url(../../images/background.png), linear-gradient(#241046, #2b1657);
    background-position: 0 0, 0 0;
    background-size: contain, auto;
    background-clip: padding-box;
    padding-top: 112px;
    padding-bottom: 204px;
    position: relative;
    overflow: hidden;
}
.benefits__row {
    max-width: 1680px;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    padding-left: 182px;
    padding-right: 182px;
    display: flex;
    left: 50%;
}
.benefits__column {
    padding-left: 0;
    padding-right: 40px;
}
.benefits__label {
    padding-bottom: 20px;
    background-image: linear-gradient(91deg, #ff5621, #ffa621);
    display: inline-block;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.benefits__title {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 60px;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.benefits__text {
    padding-top: 56px;
    padding-left: 40px;
    color: rgba(255, 255, 255, .7);
    margin-bottom: 0;
    font-size: 18px;
    line-height: 160%;
    max-width: 408px;
    margin-top: 0;
}
.benefits__container {
    max-width: 1680px;
    justify-content: space-around;
    margin: 136px 0 0;
    padding-left: 182px;
    padding-right: 182px;
    display: flex;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
}
.benefits__block:first-child {
    flex: 0 auto;
    justify-content: space-between;
    margin-top: 344px;
    margin-right: -120px;
    display: block;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: translate(69px, 59px);
}
.benefits__block:nth-child(2) {
    flex: 0 auto;
}
.benefits__block:last-child {
    z-index: 1;
    flex: 0 auto;
    margin-top: 0;
    display: block;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0%;
    transform: translate(-73px, -69px);
}
.benefits__img {
    width: 236px;
    box-shadow: 17px 11px 150px #25124d;
}
.benefits__image {
    width: 100%;
    text-align: center;
    display: inline-block;
}
.benefits__photo {
    width: 236px;
    float: none;
    clear: none;
    border-radius: 16px;
    box-shadow: -29px 45px 200px #25124d;
}
.benefits__photo_min {
    display: none;
}
.benefits__photo_mobile {
    display: none;
}
.benefits__meteor {
    z-index: 0;
    width: 204px;
    position: absolute;
    top: 16px;
    bottom: auto;
    left: 50%;
    right: 0%;
}
.benefits__planet {
    z-index: 0;
    width: 451px;
    position: absolute;
    top: 278px;
    bottom: auto;
    left: auto;
    right: -146px;
}

@media screen and (max-width: 991px) {
    .benefits {
        padding-bottom: 279px;
    }
    .benefits__row {
        padding-left: 24px;
        padding-right: 24px;
    }
    .benefits__label {
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 20px;
    }
    .benefits__title {
        font-size: 36px;
    }
    .benefits__text {
        font-size: 14px;
        line-height: 170%;
        max-width: 392px;
    }
    .benefits__container {
        margin-top: 88px;
        padding-left: 24px;
        padding-right: 24px;
    }
    .benefits__photo_min {
        display: block;
        width: 150px;
    }
    .benefits__photo_mobile {
        display: block;
        width: 150px;
    }
    .benefits__img {
        display: none;
    }
    .benefits__photo {
        display: none;
    }
    .benefits__block:first-child {
        top: auto;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: translate(48px, 95px);
    }
    .benefits__block:last-child {
        margin-top: 0;
        margin-right: 0;
        top: 0%;
        bottom: auto;
        left: auto;
        right: 0%;
        transform: translate(-54px, -76px);
    }
    .benefits__planet {
        width: 400px;
        top: auto;
        bottom: 0%;
        left: auto;
        right: -18%;
        transform: translate(0);
    }
}

@media screen and (max-width: 767px) {
    .benefits {
        padding-top: 75px;
    padding-bottom: 200px;
    }
    .benefits__row {
        padding-left: 16px;
        padding-right: 16px;
        display: block;
    }
    .benefits__column {
        padding: 0;
    }
    .benefits__label {
        font-size: 16px;
        padding-bottom: 12px;
    }
    .benefits__title {
        font-size: 28px;
        line-height: 150%;
    }
    .benefits__text {
        padding: 24px  54px 0 0;
        max-width: 100%;
    }
    .benefits__container {
        padding-left: 16px;
        padding-right: 16px;
    }
    .benefits__block:last-child {
        margin-top: 0;
        margin-right: 0;
        top: 0%;
        bottom: auto;
        left: auto;
        right: 0%;
        transform: translate(-37px, -48px);
    }
    .benefits__photo_mobile {
        width: 100px;
    }
    .benefits__block:first-child {
        top: 0%;
        bottom: auto;
        left: 0%;
        right: auto;
        transform: translate(26px, -196px);
    }
    .benefits__photo_min {
        width: 102px;
    }
    .benefits__meteor {
        display: none;
    }
    .benefits__planet {
        width: 331px;
    right: -22%;
    }
}

@media screen and (max-width: 479px) {
    .benefits__text {
        padding-right: 32px;
    }
    .benefits__block:first-child {
        transform: translate(28px, -244px);
    }
}