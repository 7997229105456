.news__container {
    z-index: 2;
    max-width: 1440px;
    margin-left: 0;
    margin-right: 0;
    position: relative;
}
.news__window {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.news__cards {
    display: flex;
}
.news__nav {
    z-index: 10;
    text-align: right;
    margin-right: 0;
    position: absolute;
    top: -100px;
    bottom: auto;
    left: auto;
    right: 0%;
    display: flex;
}
.news__arrow {
    width: 28px;
    height: 28px;
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
    margin-right: 10px;
}
.news__arrow:hover {
    color: #fff;
}

@media screen and (max-width: 991px) {
    .news__nav {
        top: -80px;
    }
}

@media screen and (max-width: 767px) {
    .news__nav {
        display: none;
    }
    .news__window {
        overflow-x: scroll;
        overflow-y: visible;
    }
    .news__window::-webkit-scrollbar {
        width: 0;
      }
}