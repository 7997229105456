.new__container {
    z-index: 1;
    width: 785px;
    padding-top: 80px;
    padding-bottom: 120px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.new__data {
    text-align: left;
    justify-content: flex-start;
    z-index: 1;
    color: rgba(255, 255, 255, .6);
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    display: flex;
    position: relative;
}
.new__title {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: left;
    padding-right: 60px;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
}
.new__img {
    height: 448px;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}
.new__text:first-child {
    margin-top: 24px;
}
.new__text {
    color: rgba(255, 255, 255, .7);
    margin-bottom: 10px;
    margin-top: 0;
    font-size: 18px;
    line-height: 160%;
}
.new__link {
    color: #fff;
    font-size: 18px;
    line-height: 160%;
    text-decoration: underline;
}
.new__back {
    z-index: 0;
    width: 1518px;
    height: auto;
    max-width: none;
    position: absolute;
    top: -547px;
    bottom: auto;
    left: auto;
    right: -654px;
    overflow: visible;
    transform: translate(0, 7px);
}
.new__back:last-child {
    width: 2000px;
    max-width: none;
    position: absolute;
    top: -937px;
    bottom: auto;
    left: -1305px;
    right: auto;
}
.new__column {
    margin-top: 18px;
}
.new__head {
    margin-top: 20px;
    font-size: 38px;
    line-height: 44px;
    font-weight: bold;
    color: rgba(255, 255, 255, .7);
}
.new__subhead {
    color: #fff;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
}
.new__name {
    color: #fff;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 10px;
}
.new__video {
    width: 100%;
    height: 441px;
}
@media screen and (max-width: 991px) {
    .new__container {
        width: auto;
        padding-left: 72px;
        padding-right: 72px;
    }
    .new__data {
        font-size: 14px;
    }
    .new__title {
        font-size: 36px;
    }
    .new__text {
        font-size: 14px;
    }
    .new__video {
        width: 100%;
        height: 415px;
    }
    .new__back {
        width: 900px;
        top: 16px;
        right: -7px;
        overflow: visible;
        transform: translate(257px, -300px);
    }
}

@media screen and (max-width: 767px) {
    .new__title {
        padding-right: 54px;
        font-size: 28px;
        line-height: 150%;
    }
    .new__container {
        padding: 56px 40px 100px;
    }
    .new__img {
        height: auto;
    }
    .new__back {
            width: 784px;
            max-width: none;
            top: -296px;
            bottom: auto;
            left: auto;
            right: -246px;
            transform: translate(0);
    }
}

@media screen and (max-width: 479px) {
    .new__text:first-child {
        margin-top: 8px;
    }
    .new__video {
        height: 222px;
    }
    .new__back {
        width: 500px;
        top: -182px;
        bottom: auto;
        left: auto;
        right: -140px;
    }
}