.summit {
    justify-content: center;
    display: flex;
}
.summit__cards {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.summit__card {
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    z-index: 1;
    text-align: center;
    position: relative;
}
.summit__frame:first-child {
    padding-right: 12px;
}
.summit__frame:last-child {
    padding-left: 12px;
}
.summit__container {}
.summit__img {
    width: 54px;
    height: 54px;
}
.summit__image {
    z-index: 0;
    object-fit: fill;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    width: 140px;
    height: 124px;
}
.summit__title {
    padding-top: 12px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 170%;
    position: static;
    color: #fff;
}
.summit__subtitle {
    z-index: 10;
    font-size: 14px;
    position: relative;
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 0;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(91deg, #ff5621, #ffa621);
}
@media screen and (max-width: 1045px) {
    .summit__frame {
        width: 26px;
        height: 64px;
    }
}

@media screen and (max-width: 991px) {
    .summit {
        flex-direction: column;
    }
    .summit__frame {
        width: 56px;
        height: 115px;
    }
    .summit__card {
        padding-left: 8px;
        padding-right: 8px;
    }
    .summit__img {
        width: 48px;
        height: 48px;
    }
    .summit__title {
        padding-bottom: 12px;
    }
}

@media screen and (max-width: 767px) {
    .summit__frame {
        display: none;
    }
    .summit__card {
        width: 216px;
    padding-bottom: 44px;
    padding-left: 20px;
    padding-right: 20px;
    }
    .summit__title {
        padding-top: 10px;
    padding-bottom: 0;
    }
}

@media screen and (max-width: 479px) {
    .summit__card {
        padding-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
    }
    .summit__subtitle {
        font-size: 12px;
    }
}