.App {
  background-color: #230f43;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  z-index: 0;
    max-width: none;
    background-image: url(../../images/main.png);
    background-position: 0 0, 0 0;
    background-repeat: repeat, repeat;
    background-size: contain, auto;
    background-attachment: scroll, scroll;
    padding-top: 48px;
    display: block;
    position: relative;
    left: auto;
    overflow: hidden;
}
@media screen and (max-width: 991px) {
  .App {
    padding-top: 24px;
  }
}
@media screen and (max-width: 767px) {
  .App {
    padding-top: 16px;
  }
}