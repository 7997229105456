.clients {}
.clients__title {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 60px;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    color: #fff;
    margin-top: 100px;
    margin-bottom: 28px;
    text-align: center;
}
.clients__container {
    padding: 0 72px 160px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
.clients__img {
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
    height: 52px;
    max-width: none;
    min-width: auto;
    opacity: 1;
    object-fit: contain;
    padding-left: 36px;
    padding-right: 36px;
}
.clients__img-link {
    
}
.clients__moon {
    left: auto;
    right: 89%;
    width: 222px;
    position: absolute;
    top: 147px;
    bottom: auto;
}
.clients__meteor {
    left: 85%;
    right: auto;
    max-width: 157px;
    position: absolute;
    top: 163px;
    bottom: auto;
}

@media screen and (max-width: 991px) {
    .clients__title {
        margin-top: 80px;
        font-size: 36px;
        padding-right: 0;
    }
    .clients__meteor {
        top: 140px;
    }
    .clients__moon {
        width: 190px;
        top: 120px;
        left: -12%;
    }
    .clients__container {
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .clients__img {
        margin-top: 12px;
        margin-bottom: 12px;
        height: 40px;
    }
}

@media screen and (max-width: 767px) {
    .clients__container {
        padding-left: 16px;
        padding-right: 16px;
    }
    .clients__title {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 150%;
    }
    .clients__img {
        padding-left: 20px;
        padding-right: 20px;
    }
    .clients__moon {
        width: 140px;
        top: 110px;
        left: -9%;
    }
    .clients__meteor {
        display: none;
    }
}

@media screen and (max-width: 479px) {
    .clients__container {
        padding-bottom: 80px;
    }
    .clients__img {
        height: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }
}