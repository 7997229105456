.wrapper {
    z-index: 0;
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    object-fit: fill;
    display: block;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    overflow: visible;
    transform: translate(0%);
}
.wrapper__img:first-child {
    z-index: 0;
    width: 1518px;
    height: auto;
    max-width: none;
    position: absolute;
    top: -547px;
    bottom: auto;
    left: auto;
    right: -654px;
    overflow: visible;
    transform: translate(0, 7px);
}
.wrapper__img:last-child {
    width: 2000px;
    max-width: none;
    position: absolute;
    top: -937px;
    bottom: auto;
    left: -834px;
    right: auto;
}

@media screen and (max-width: 991px) {
    .wrapper__img:first-child {
        width: 900px;
        top: 16px;
        right: -7px;
        overflow: visible;
        transform: translate(257px, -300px);
    }
}

@media screen and (max-width: 767px) {
    .wrapper__img:first-child {
        width: 784px;
        max-width: none;
        top: -296px;
        bottom: auto;
        left: 228px;
        right: 4%;
        transform: translate(0);
    }
}