.partners {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    left: 0%;
    z-index: 1;
    padding-bottom: 145px;
}
.partners__text {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.partners__link {
    text-align: right;
    justify-content: flex-end;
    padding-top: 3px;
    padding-right: 72px;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0;
    text-decoration: none;
    transition: opacity .3s;
}
.partners__all {
    z-index: 1;
    color: rgba(255, 255, 255, .6);
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    display: flex;
    position: relative;
}
.partners__title {
    z-index: 1;
    color: rgba(255, 255, 255, .6);
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    display: flex;
    position: relative;
}
.partners__overflow {
    display: flex;
    overflow: hidden;
    width: 100%;
}
.partners__wrapper {
    -webkit-animation: logoloop 90s linear infinite;
    animation-play-state: running;
    max-height: none;
    max-width: none;
    mix-blend-mode: normal;
    flex-flow: row;
    align-content: stretch;
    justify-content: flex-start;
    display: flex;
    position: static;
}
@keyframes logoloop {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.partners__img {
    width: auto;
    height: 52px;
    max-width: none;
    min-width: auto;
    opacity: 1;
    object-fit: contain;
    padding-left: 36px;
    padding-right: 36px;
}
.partners__overflow:hover .partners__wrapper {
    animation-play-state: paused;
}
.partners__img-link {}

@media screen and (max-width: 991px) {
    .partners {
        padding-bottom: 110px;
        padding-top: 24px;
    }
    .partners__link {
        padding-top: 0;
        right: 24px;
        position: absolute;
        padding-right: 0;
    }
    .partners__all {
        font-size: 14px;
    }
    .partners__title {
        font-size: 14px;
    }
    .partners__img {
        height: 40px;
    }
}

@media screen and (max-width: 767px) {
    .partners {
        padding-top: 32px;
    }
    .partners__img {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 479px) {
    .partners__img {
        height: 32px;
    padding-left: 16px;
    padding-right: 16px;
    }
}